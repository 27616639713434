<script>
import animationData from "@/components/widgets/spxnqpau.json";
import Lottie from "@/components/widgets/lottie.vue";

export default {
  data() {
    return {
      lastPath: null,
      defaultOptions: { animationData: animationData },
    };
  },
  created () {
    this.lastPath = this.$router.options.history.state.back
  },
  computed: {
    prevRoutePatch () {
      return this.lastPath ? this.lastPath : '/dashboard'
    }
  },
  components: {
    lottie: Lottie
  }
}
</script>

<template>
  <!-- auth-page wrapper -->
  <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-5">
            <div class="card overflow-hidden">
              <div class="card-body p-4">
                <div class="text-center">

                  <lottie
                      colors="primary:#405189,secondary:#0ab39c"
                      :options="defaultOptions"
                      style="height:7.5rem;width:7.5rem;"

                  />
                  <h4 class="text-uppercase">{{ $t("t-404-title") }}</h4>
                  <p class="text-muted mb-4">{{ $t("t-404-text") }}</p>
                  <router-link v-if="loggedIn" to="/dashboard" class="btn btn-success" style="margin-right: 5px;">
                    <i class="mdi mdi-login text-muted fs-16 align-middle me-1"></i>
                    {{ $t("t-dashboard") }}
                  </router-link>
                  <router-link  v-if="!loggedIn" :to="prevRoutePatch" class="btn btn-success">
                    <i class="mdi mdi-home me-1"></i>
                    {{ $t("t-back-to-site") }}
                  </router-link>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->

        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->
  </div>
  <!-- end auth-page-wrapper -->
</template>
